import React from "react";
import {
  Text,
  Link,
  Image,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import NavLinkAdv from "../../utils/NavLinkAdv";
import "./blockImgRight.scss";
import i18next from "i18next";

const BlockImageRight = (props) => {
  const datasource = props.fields || {};
  let designVariant =
    props.fields.designVariant && props.fields.designVariant.value;

  let classesBlock;

  if (props.fields.BackgroundImage.value.length > 0) {
    classesBlock = {
      backgroundImage: `url(` + props.fields.BackgroundImage.value.src + `)`,
      backgroundRepeat: `no-repeat`,
      backgroundSize: `cover`,
    };
  }

  if (
    datasource !== undefined &&
    Object.getOwnPropertyNames(datasource).length >= 1
  ) {
    return (
      <div className={"section blockImgRight " + designVariant + ""}>
        {" "}
        {/* Add this : style={classesBlock} to have background imgage (need to do the css) */}
        <div className="row">
          <div className="block-left">
            <Text field={props.fields.Title} tag="h2" className="title" />
            <Text
              tag="p"
              field={props.fields.Description}
              className={"content"}
            />
            {props.fields.Link.value.length > 0 && (
              <NavLinkAdv to={props.fields.Link.value.href} className="link">
                <span>{props.fields.Link.value.title}</span>
                <i className="fa fa-angle-right" />
              </NavLinkAdv>
            )}
          </div>
          <div className="block-right">
            <Image loading="lazy" field={props.fields.Image} />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="component-no-datasource">
        {i18next.t("no-datasource") +
          " " +
          props.rendering.componentName +
          " " +
          i18next.t("component")}
      </div>
    );
  }
};

export default withSitecoreContext()(BlockImageRight);
